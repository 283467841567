<template>
    <div>
        <b-row>
            <b-col class="text-right">
                <!-- <b-button variant="primary" @click="openAddDocumentModal()">Add document</b-button> -->
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <b-table striped hover 
                         :items="tableData.dataSource" 
                         :fields="tableData.tableColumns" 
                         :busy="tableData.isLoading"
                         :per-page="tableData.resultsPerPage"
                         id="documentTable"
                         :current-page="tableData.currentPage"
                         sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    <template #cell(uploadDate)="row">
                      {{row.item.uploadDate | dateTimeFilter}}
                    </template>
                    
                    <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">                            
                            <!-- <b-button @click="deleteDocumentModal(row.item)" variant="red" size="sm" class="ml-2">
                                Remove
                            </b-button> -->
                            <b-button @click="download(row.item)" variant="primary" size="sm" class="ml-2">Download
                            </b-button>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row align-h="center" >
            <b-pagination
                v-model="tableData.currentPage"
                :total-rows="documentRows"
                :per-page="tableData.resultsPerPage"
                aria-controls="documentTable"
            ></b-pagination>
        </b-row>

        <b-modal id="add-document-modal" title="Add Document" hide-footer @close="closeAddModal()" size="md">
          <b-row>
            <b-col>
              <b-form-file
                  v-model="documentDetails.imageFile"
                  @change="filesUploaded"
                  placeholder="Choose a file..."
                  drop-placeholder="Drop file here..."
              ></b-form-file>
              <label class="mt-3">Document Description</label>
              <b-form-input v-model="documentDetails.description"></b-form-input>
            </b-col>
          </b-row>
          <hr class="mt-3" />
          <b-row class="mt-3">
            <b-col cols="12" class="text-right">
              <b-button variant="red" @click="closeAddModal" class="mr-2 ml-2" squared>Close</b-button>
              <b-button variant="primary" squared @click="saveDocument" :disabled="isDocumentConverting === true">{{isDocumentConverting === true ? 'Converting...' : 'Save'}}</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
            </b-col>
          </b-row>
        </b-modal>
        
        <b-modal id="showDocument-modal" hide-footer @close="closeViewDocument" size="xl">
            <b-row>
                <b-col cols="12" v-if="documentTypeDisplay === 'none'">
                    <p class="text-center">
                        System could not handle file type. To view the document please download it.
                    </p>
                </b-col>
                <b-col cols="12" v-if="documentTypeDisplay === 'image'">
                    <img src="" alt="">
                </b-col>
                <b-col cols="12" v-if="documentTypeDisplay === 'pdf'">
                    <p>implementation required</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="red" @click="hideModal" class="mr-2 ml-2" squared>Close</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="document-delete-modal" hide-footer title="Delete Document" @close="closeDeleteDocumentModal()">
            <b-row>
                <b-col cols="12" class="text-center">
                    Are you sure you want to delete this document?
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-center">
                    <b-button variant="red" size="sm" class="mr-2" squared @click="closeDeleteDocumentModal()">No</b-button>
                    <b-button variant="primary" size="sm" squared @click="completeDeleteDocument()">Yes</b-button>
                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
    data: () => ({
        documentTypeDisplay: 'none',
        isDocumentConverting: false,
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            selectedDocument: {
              documentId: null,
              ticketId: null,
              documentName: null,
              description: null,
              uploadDate:null
            },
            tableColumns: [
                {
                    label: 'File Name',
                    key: 'documentName',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date',
                    key: 'uploadDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        documentDetails: {
          ticketId: null,
          documentName: null,
          description: null,
          imageFile: null,
        },
        docUpload: {}
    }),
    created(){
        this.tableData.dataSource = this.selectedJobCard.documents
        this.tableData.isLoading = false
    },
    methods:{
      ...mapActions(["uploadDocument", 'downloadDocumentForJobCard', 'deleteDocument']),
      ...mapActions('notificationService', ['addErrorMessage', 'clearMessage']),
      
        closeAddModal() {
            this.$bvModal.hide('add-document-modal')
        },
        openAddDocumentModal() {
            this.$bvModal.show('add-document-modal')
        },
        hideModal() {
            this.$bvModal.hide('showDocument-modal')
        },
        download(rowItem){
            this.$store.commit('setDownloadDocumentForJobCardRequest', rowItem.documentId)
            this.downloadDocumentForJobCard()
            .then((response) => {
                const linkSource = `data:application/pdf;base64,${response.data.base64Data}`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = response.data.fileName;
                downloadLink.click();
            })
        },
        closeViewDocument(){
          this.$root.$emit('bv::hide::modal', 'showDocument-modal', '#btnShow')
        },

        deleteDocumentModal(rowItem){
            this.selectedDocument = rowItem
            this.$root.$emit('bv::show::modal', 'document-delete-modal', '#btnShow')
        },
        closeDeleteDocumentModal(){
            this.selectedDocument = {}
            this.$root.$emit('bv::hide::modal', 'document-delete-modal', '#btnShow')
        },
        completeDeleteDocument(){
            
            this.$store.commit('setDocumentId', this.selectedDocument.documentId)
            this.deleteDocument()
            .then(() => {
             
                let item = this.selectedJobCard.documents.findIndex(element => element.documentId === this.selectedDocument.documentId)
                this.selectedJobCard.documents.splice(item, 1)
                this.closeDeleteDocumentModal()
            })
            .catch((error) => {
              
                console.log(error)
                this.closeDeleteDocumentModal()
            })
        },

        filesUploaded(e){
          const selectedDocument = e.target.files[0]
          if (selectedDocument.size > 20000000) {
            this.clearMessage()
            this.addErrorMessage({error: '',message: 'Document to big to be uploaded'})
            return
          }
          this.createBase64File(selectedDocument)
          this.uploadFile = null
          },
        createBase64File(file){
          this.isDocumentConverting = true
          const reader = new FileReader()
          
          let that = this 

          reader.onload = (e) => {
            this.file1 = e.target.result

            that.docUpload.base64Data = this.file1
            that.docUpload.description = that.documentDetails.description
            that.docUpload.date = new Date ()
            that.docUpload.documentName = file.name
            that.docUpload.ticketId = that.selectedJobCard.ticketId

            that.isDocumentConverting = false
          }
          reader.readAsDataURL(file)
      },

        saveDocument() {
          this.docUpload.description = this.documentDetails.description
  
          this.$store.commit('setCreateDocumentRequest', this.docUpload)
          this.uploadDocument()
          .then((response) => {
            let docObject = response.data.result
            docObject.description = this.documentDetails.description

            this.selectedJobCard.documents.push(docObject)
          })
          this.closeAddModal()
        },
      },
    computed:{
        ...mapState(['selectedJobCard']),
        documentRows() {
          return this.tableData.dataSource.length
      },
    }
}
</script>